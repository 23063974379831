@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.layout {
  background-color: $white-200;

  &__main {
    > div {
      @include stretch-to-full-height;

      > div {
        @include stretch-to-full-height;

        justify-content: center;
      }
    }
  }
}

.not-found-page {
  @include stretch-to-full-height;

  justify-content: center;
  padding-top: 160px;
  padding-bottom: 220px;

  @include mq($to: xxl) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include mq($to: md) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &__control {
    @include mq($to: sm) {
      width: 100%;
      max-width: 100%;
    }

    .button {
      @include mq($to: sm) {
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &__controls {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    margin-top: 30px;

    @include mq($to: md) {
      justify-content: center;
    }

    @include mq($to: sm) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      gap: 8px;
    }
  }

  &__description {
    margin-top: 10px;

    p {
      color: $black-300;
      font-family: $font-family--headings;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 26.4px */
      letter-spacing: -0.48px;
      margin-top: 0;
      margin-bottom: 0;

      @include mq($to: md) {
        font-size: 22px;
      }
    }
  }

  &__inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    @include mq($to: md) {
      display: block;
    }
  }

  &__left {
    flex: 1 1 auto;

    @include mq($to: md) {
      text-align: center;
    }
  }

  &__or {
    color: $purple-500;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  &__right {
    @include reset-text-indents;

    flex: 0 0 auto;
    margin-left: 60px;

    @include mq($to: md) {
      text-align: center;
      margin-left: 0;
      margin-top: 60px;
    }
  }

  &__title {
    h1 {
      color: $purple-500;
      font-family: $font-family--headings;
      font-size: 96px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
